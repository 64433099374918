import React from 'react'

import * as Styles from './AppAuth.styles'
import { Button } from 'src/components/Button/Button'

export interface AppAuthProps {
    onEnter: () => void
}

export function AppAuth(props: AppAuthProps) {

    const { onEnter } = props

    return (
        <Styles.Root>

            <Button
                color="primary"
                size="lg"
                onClick={onEnter}
            >
                Authenticate with Gitlab
            </Button>

        </Styles.Root>
    )
}
