import React from 'react'

export interface IconProps {
    className?: string
    children: React.ReactNode
}

export function Icon(props: IconProps) {
    const { className, children } = props
    return (
        <svg className={className} style={{ width:24, height:24 }} viewBox="0 0 24 24">
            {children}
        </svg>
    )
}
