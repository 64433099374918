import React from 'react'

import { AppCurrentUserContext } from 'src/packages/app/contexts/AppCurrentUserContextProvider'

import { useLocation, useNavigate } from 'react-router-dom'
import { NAME } from 'src/constants'

import * as Styles from './AppHeader.styles'
import { AppHeaderUser } from './AppHeaderUser'
import { NavLink } from 'react-router-dom'
import { Typography } from 'src/components/Typography/Typography'
import { IconButton } from 'src/components/IconButton/IconButton'
import { MenuIcon } from 'src/components/Icons/MenuIcon'
import { Drawer } from 'src/components/Drawer/Drawer'

export interface AppHeaderProps {

}

interface AppHeaderLocationState {
    headerMenuOpen: boolean
}

export function AppHeader(props: AppHeaderProps) {

    const currentUser = React.useContext(AppCurrentUserContext)

    const location = useLocation()
    const navigate = useNavigate()

    const open = React.useMemo(() => {
        if (location.state) {
            const state = location.state as AppHeaderLocationState
            if (state.headerMenuOpen) {
                return true
            }
        }
        return false
    }, [
        location
    ])

    const handleOpen = () => {
        navigate({
            pathname: location.pathname,
            search: location.search,
        }, {
            state: {
                headerMenuOpen: true
            }
        })
    }

    const handleClose = () => {
        navigate(-1)
    }

    return (
        <>

            <Styles.Header>

                <IconButton edge="start" onClick={handleOpen}>
                    <MenuIcon/>
                </IconButton>

                <Typography variant="h6" ellipsis>
                    {NAME}
                </Typography>

                <Styles.Nav>

                    <Styles.NavLink as={NavLink} to="/tracker/timer">
                        <span>Timer</span>
                    </Styles.NavLink>

                    <Styles.NavLink as={NavLink} to="/tracker/timesheet">
                        <span>Timesheet</span>
                    </Styles.NavLink>

                </Styles.Nav>

                <AppHeaderUser
                    currentUser={currentUser}
                />

            </Styles.Header>

            <Drawer
                open={open}
                onOpenChange={handleClose}
            >

                <Styles.Header>

                    <IconButton edge="start" onClick={handleClose}>
                        <MenuIcon/>
                    </IconButton>

                    <Typography variant="h6" ellipsis>
                        {NAME}
                    </Typography>

                </Styles.Header>

                <Styles.DrawerNavList css={{ flexGrow:1 }}>

                    <Styles.DrawerNavListItem>
                        <Styles.DrawerNavLink as={NavLink} replace to="/tracker/timer">
                            <svg style={{ width:24, height:24 }} viewBox="0 0 24 24">
                                <path fill="currentColor" d="M19.03 7.39L20.45 5.97C20 5.46 19.55 5 19.04 4.56L17.62 6C16.07 4.74 14.12 4 12 4C7.03 4 3 8.03 3 13S7.03 22 12 22C17 22 21 17.97 21 13C21 10.88 20.26 8.93 19.03 7.39M13 14H11V7H13V14M15 1H9V3H15V1Z" />
                            </svg>
                            Timer
                        </Styles.DrawerNavLink>
                    </Styles.DrawerNavListItem>

                    <Styles.DrawerNavListItem>
                        <Styles.DrawerNavLink as={NavLink} replace to="/tracker/timesheet">
                            <svg style={{ width:24, height:24 }} viewBox="0 0 24 24">
                                <path fill="currentColor" d="M21 11.11V7A2 2 0 0 0 19 5H15V3A2 2 0 0 0 13 1H9A2 2 0 0 0 7 3V5H3A2 2 0 0 0 1 7V18A2 2 0 0 0 3 20H10.26A7 7 0 1 0 21 11.11M9 3H13V5H9M19 20A5 5 0 0 1 13 20A5 5 0 1 1 19 20M15 13H16.5V15.82L18.94 17.23L18.19 18.53L15 16.69V13" />
                            </svg>
                            Timesheet
                        </Styles.DrawerNavLink>
                    </Styles.DrawerNavListItem>

                </Styles.DrawerNavList>

                <Styles.DrawerNavList>

                    <Styles.DrawerNavListItem>
                        <Styles.DrawerNavLink as={NavLink} replace to="/about">
                            <svg style={{ width:24, height:24 }} viewBox="0 0 24 24">
                                <path fill="currentColor" d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
                            </svg>
                            About
                        </Styles.DrawerNavLink>
                    </Styles.DrawerNavListItem>

                </Styles.DrawerNavList>

            </Drawer>

        </>
    )
}
