import React from 'react'
import { ComponentProps } from '@stitches/react'
import * as Styles from './Drawer.styles'

export interface DrawerProps extends ComponentProps<typeof Styles.Root> {

}

export function Drawer(props: DrawerProps) {

    const { onOpenChange, children } = props

    return (
        <Styles.Root {...props} onOpenChange={onOpenChange}>
            <Styles.Portal>
                <Styles.Container>

                    <Styles.Overlay
                        onClick={() => {
                            if (onOpenChange) {
                                onOpenChange(false)
                                document.body.style.pointerEvents = 'unset'
                            }
                        }}
                    />

                    <Styles.Content
                        onInteractOutside={(event) => {
                            event.preventDefault()
                        }}
                    >
                        {children}
                    </Styles.Content>

                </Styles.Container>
            </Styles.Portal>
        </Styles.Root>
    )
}
