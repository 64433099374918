import { styled } from 'src/stitches'
import * as AreaPrimitive from '@radix-ui/react-scroll-area'

export const Root = styled(AreaPrimitive.Root, {
    flexGrow: 1,
    flexShrink: 1,
    minHeight: 0,
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: '$gray3',
})

export const Viewport = styled(AreaPrimitive.Viewport, {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column',
    minHeight: '100%',
    '& > div': {
        flexGrow: 1,
        display: 'flex !important',
        flexFlow: 'column',
        minHeight: '100%',
    }
})

export const Scrollbar = styled(AreaPrimitive.Scrollbar, {
    userSelect: 'none',
    touchAction: 'none',
    display: 'flex',
    padding: 1,
    '&[data-orientation="vertical"]': {
        width: 4,
    },
    '&[data-orientation="horizontal"]': {
        flexDirection: 'column',
        height: 4,
    },
})

export const Thumb = styled(AreaPrimitive.Thumb, {
    flex: 1,
    position: 'relative',
    zIndex: 1000,
    background: '$primary9',
    overflow: 'hidden',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        minWidth: 40,
        minHeight: 40,
    }
})

export const Corner = AreaPrimitive.Corner
