import React from 'react'
import { Icon, IconProps } from './Icon'

export type MenuIconProps = Omit<IconProps, 'children'>

export function MenuIcon(props: MenuIconProps) {
    return (
        <Icon {...props}>
            <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"/>
        </Icon>
    )
}
