import React from 'react'
import { ComponentProps } from '@stitches/react'
import * as Styles from './Content.styles'

export interface ContentProps extends ComponentProps<typeof Styles.Root> {

}

export function Content(props: ContentProps) {

    const { children } = props

    return (
        <Styles.Root>

            <Styles.Viewport>
                {children}
            </Styles.Viewport>

            <Styles.Scrollbar orientation="horizontal">
                <Styles.Thumb/>
            </Styles.Scrollbar>

            <Styles.Scrollbar orientation="vertical">
                <Styles.Thumb/>
            </Styles.Scrollbar>

            <Styles.Corner/>

        </Styles.Root>
    )
}
