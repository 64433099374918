import { styled } from 'src/stitches'

export const Root = styled('button', {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    padding: '0 $2',
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 500,
    borderRadius: '$1',
    boxShadow: `$box4`,
    cursor: 'pointer',

    '&:focus-visible': {
        boxShadow: `0 0 0 2px black`
    },

    '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },

    variants: {

        color: {
            primary: {
                color: '$primary1',
                backgroundColor: '$primary9',
                '&:hover:not(:disabled)': {
                    backgroundColor: '$primary10',
                    boxShadow: `$boxSoft4`,
                },
                '&:active:not(:disabled)': {
                    backgroundColor: '$primary11',
                },
            },
        },

        size: {
            'sm': {
                height: 32,
            },
            'lg': {
                height: 48,
            },
        },

    }
})
