import React from 'react'

import { QueryClient, QueryClientProvider } from 'react-query'

export interface QueryContextProviderProps {
    children: React.ReactNode
}

const client = new QueryClient()

export function QueryContextProvider(props: QueryContextProviderProps) {
    const { children } = props

    return (
        <QueryClientProvider client={client}>
            {children}
        </QueryClientProvider>
    )
}
