import React from 'react'

import { GITLAB_URL } from '../constants'
import { GITLAB_APPLICATION_CLIENT_ID, GITLAB_APPLICATION_REDIRECT_URL, GITLAB_APPLICATION_SCOPE } from '../constants'

import { AuthProvider } from 'react-oidc-context'

export interface OidcContextProviderProps {
    children: React.ReactNode
}

export function OidcContextProvider(props: OidcContextProviderProps) {
    const { children } = props

    return (
        <AuthProvider
            authority={GITLAB_URL}
            client_id={GITLAB_APPLICATION_CLIENT_ID}
            redirect_uri={GITLAB_APPLICATION_REDIRECT_URL}
            scope={GITLAB_APPLICATION_SCOPE}
            automaticSilentRenew
            loadUserInfo={false}
            response_type="code"
            metadata={{
                issuer: GITLAB_URL,
                authorization_endpoint: GITLAB_URL + '/oauth/authorize',
                token_endpoint: GITLAB_URL + '/oauth/token',
                revocation_endpoint: GITLAB_URL + '/oauth/revoke',
                introspection_endpoint: GITLAB_URL + '/oauth/introspect',
                userinfo_endpoint: GITLAB_URL + '/oauth/userinfo',
                jwks_uri: GITLAB_URL + '/oauth/discovery/keys',
            }}
            onSigninCallback={() => {
                window.history.replaceState(null, '', '/')
            }}
        >
            {children}
        </AuthProvider>
    )
}
