import React from 'react'
import { ComponentProps } from '@stitches/react'
import * as Styles from './Typography.styles'

export interface TypographyProps extends ComponentProps<typeof Styles.Root> {

}

export function Typography(props: TypographyProps) {
    return (
        <Styles.Root {...props}/>
    )
}
