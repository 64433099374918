import React from 'react'

import { CurrentUser } from '../types/CurrentUser'
import { useCurrentUserQuery } from '../operations/graphql/currentUser'

import { AppLoading } from '../components/AppLoading/AppLoading'
import { AppError } from '../components/AppError/AppError'

export const AppCurrentUserContext = React.createContext<CurrentUser>({} as CurrentUser)

export interface AppCurrentUserContextProviderProps {
    children: React.ReactNode
}

export function AppCurrentUserContextProvider(props: AppCurrentUserContextProviderProps) {

    const { children } = props

    const { isLoading, error, data } = useCurrentUserQuery(undefined, {
        refetchOnWindowFocus: false,
        retry: false,
    })

    if (isLoading) {
        return (
            <AppLoading/>
        )
    }

    if (error) {
        return (
            <AppError
                error={error}
            />
        )
    }

    if (!data?.currentUser) {
        return null
    }

    return (
        <AppCurrentUserContext.Provider value={data.currentUser}>
            {children}
        </AppCurrentUserContext.Provider>
    )
}
