import { styled } from 'src/stitches'

export const Header = styled('header', {
    flexShrink: 0,
    position: 'relative',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    gap: '$2',
    height: 56,
    padding: '0 $3',
    boxShadow: '$boxSoft2',
})

export const Nav = styled('nav', {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    alignItems: 'baseline',
    minWidth: 1,
    gap: '$2',

    '&::before': {
        content: '"Menu"',
        width: 0,
        visibility: 'hidden',
        display: 'block',
        lineHeight: '32px',
        fontSize: 20,
    },
})

export const NavLink = styled('div', {
    unset: 'all',
    flexShrink: 1,
    display: 'block',
    textDecoration: 'none',
    lineHeight: '32px',
    fontSize: 16,
    fontWeight: 500,
    color: '$blackA10',

    '& > span': {
        display: 'inline-block',
        lineHeight: '1',
        boxShadow: '0 1px 0 0 $colors$blackA6',
    },

    '&:hover': {
        color: '$blackA11',
    },

    '&.active': {
        color: '$blackA12',

        '& > span': {
            boxShadow: 'none',
        }
    },

    '@smDown': {
        display: 'none',
    },
})

export const DrawerNavList = styled('ul', {
    listStyle: 'none',
    margin: 0,
    padding: '$2 0',
})

export const DrawerNavListItem = styled('li', {

})

export const DrawerNavLink = styled('a', {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    height: 48,
    padding: '0 $3',
    fontWeight: 500,
    color: '$blackA10',

    '&:hover': {
        backgroundColor: '$blackA3',
    },

    '&.active': {
        color: '$blackA12',
    },

    '& > svg': {
        display: 'block',
        marginRight: '$3',
    }
})
