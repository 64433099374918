import React from 'react'

import { AppContext } from 'src/packages/app/contexts/AppContextProvider'

import { CurrentUser } from '../../types/CurrentUser'
import { GITLAB_URL } from 'src/constants'

import * as Styles from './AppHeaderUser.styles'

export interface AppHeaderUserProps {
    currentUser: CurrentUser
}

export function AppHeaderUser(props: AppHeaderUserProps) {

    const { currentUser } = props

    const { exit } = React.useContext(AppContext)

    return (
        <Styles.Menu>

            <Styles.MenuTrigger asChild>

                <Styles.Avatar>
                    <Styles.AvatarImage
                        src={`${GITLAB_URL}${currentUser.avatarUrl || ''}`}
                        alt={currentUser.name}
                    />
                </Styles.Avatar>

            </Styles.MenuTrigger>

            <Styles.MenuContent align="end">

                <Styles.MenuItem asChild>
                    <a href={currentUser.webUrl} target="_blank" rel="noreferrer">{currentUser.name}</a>
                </Styles.MenuItem>

                <Styles.MenuSeparator/>

                <Styles.MenuItem color="failure" onSelect={exit}>
                    Exit
                </Styles.MenuItem>

                <Styles.MenuArrow width={12} height={6} offset={20-12/2}/>

            </Styles.MenuContent>

        </Styles.Menu>
    )
}
