export const NAME = process.env.REACT_APP_NAME!
export const DESC = process.env.REACT_APP_DESC!

export const GITLAB_APPLICATION_CLIENT_ID = process.env.REACT_APP_GITLAB_APPLICATION_CLIENT_ID!
export const GITLAB_APPLICATION_REDIRECT_URL = window.location.origin + '/'
export const GITLAB_APPLICATION_SCOPE = 'api'

export const GITLAB_URL = process.env.REACT_APP_GITLAB_URL!
export const GITLAB_RESTFUL_URL = GITLAB_URL + '/api/v4'
export const GITLAB_GRAPHQL_URL = GITLAB_URL + '/api/graphql'
