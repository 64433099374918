import React from 'react'
import { ComponentProps } from '@stitches/react'
import * as Styles from './IconButton.styles'

export interface IconButtonProps extends ComponentProps<typeof Styles.Root> {

}

export function IconButton(props: IconButtonProps) {
    return (
        <Styles.Root {...props}/>
    )
}
